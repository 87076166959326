import {
    FormControl,
    Grid,
    Hidden,
    TextField,
    Button,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'
import React, { forwardRef } from 'react'
import GoogleMaps from '../maps/mapsInput'
import utils from '../../../helpers/validations'
import { factory } from '../../../helpers/factory'
import Spinner from '../../shared/spinner'
import { googleMapsService } from '../../../services/googleMapsService'
import DatePicker from 'react-datepicker'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import Autocomplete from '@material-ui/lab/Autocomplete'
import es from 'date-fns/locale/es'
import en from 'date-fns/locale/en-US'
import ca from 'date-fns/locale/ca'
import 'react-datepicker/dist/react-datepicker.css'
import i18next from 'i18next'
import setYear from 'date-fns/setYear'
import { history } from '../../../helpers/history'
import PersonIcon from '@material-ui/icons/Person'
import { serviceService } from '../../../services/servicesService'
import { SERVICES_TYPE } from '../../../constants/types'
import {
    getCustomerRequestTrip,
    getDestinationRequestTrip,
    getHasWheelChairsRequestTrip,
    getIsRequestByDropOff,
    getLuggageRequestTrip,
    getOriginRequestTrip,
    getPickUpDateRequestTrip,
    getQuantityPassengerRequestTrip,
} from '../tools'
import { SelectRequestType } from './selectRequestType'

const ORIGIN = 0
const DESTINATION = 1


const styles = {
    h2: { paddingLeft: 90, paddingTop: 25, paddingBottom: 25 }
}

class RequestTripForm extends React.Component {
    constructor(props) {
        super()
        const { tripReducer } = props
        this.state = {
            customer: getCustomerRequestTrip(tripReducer),
            origin: getOriginRequestTrip(tripReducer),
            destination: getDestinationRequestTrip(tripReducer),
            isRequestByDropOff: getIsRequestByDropOff(tripReducer),
            pickUpStart: getPickUpDateRequestTrip(tripReducer),
            quantityPassenger: getQuantityPassengerRequestTrip(tripReducer),
            wheelChairs: getHasWheelChairsRequestTrip(tripReducer),
            customerUsesWheelChair: getHasWheelChairsRequestTrip(tripReducer),
            noRestriction: false,
            timeText: '',
            quantityLuggage: getLuggageRequestTrip(tripReducer),
            destinationsZones: [],
            errors: {
                customer: { result: false, message: '' },
                origin: { result: false, message: '' },
                destination: { result: false, message: '' },
                pickUpStart: { result: false, message: '' },
            }
        }
        switch (true) {
            case i18next.language === 'en':
                registerLocale('en', en)
                this.state.timeText = 'Time'
                break
            case i18next.language === 'es':
                registerLocale('es', es)
                this.state.timeText = 'Hora'
                break
            case i18next.language === 'ca':
                registerLocale('ca', ca)
                this.state.timeText = 'Hora'
                break
            default:
                registerLocale('ca', ca)
                setDefaultLocale('ca')
                break
        }
        this.handleChange = this.handleChange.bind(this)
        this.cancelProcess = this.cancelProcess.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.setOriginAndDestination = this.setOriginAndDestination.bind(this)
    }

    handleChange(event) {
        let { name, value } = event.target
        let errors = this.state.errors
        if (errors[name]) errors[name].message = ''
        this.setState({ [name]: value })
    }

    onChangeOrigin = newValue => {
        let errors = this.state.errors
        errors.origin.message = ''
        window.selectAutoCompleteOrigin = false
        if (newValue) {
            googleMapsService
                .getLocation(newValue.description)
                .then(location => {
                    if (this.state.origin.desc !== '') {
                        this.props.clearMarker(ORIGIN)
                    }
                    this.setState({
                        origin: { desc: newValue, location },
                    })
                    this.props.setMarker(ORIGIN, location)
                })
        } else {
            this.setState({
                origin: { desc: '', location: null },
            })
            this.props.clearMarker(ORIGIN)
        }
    }

    onChangeCustomer = (event, newValue) => {
        let errors = this.state.errors
        errors.customer.message = ''

        this.setState({
            customer: newValue ? newValue : '',
            wheelChairs: newValue ? newValue.hasWheelChair : false,
        })
    }

    setRequestTripProperties(
        customerP,
        origin,
        destination,
        luggage,
        hasWheelChair,
        numPassengers,
        newTime
    ) {
        this.setState({
            customer: customerP,
            origin: origin,
            destination: destination,
            quantityPassenger: numPassengers,
            quantityLuggage: luggage,
            wheelChairs: hasWheelChair,
            pickUpStart: newTime
        })
    }

    setOriginAndDestination(zoneId, zoneName, point) {
        serviceService.getDestinationZones(zoneId).then(results => {
            let resultArray = []
            results.forEach(elementZone => {
                resultArray.push({
                    description: elementZone.name,
                    address: elementZone.stops[0]?.address,
                    location: elementZone.stops[0]?.point,
                    type: 'destinationZoneAddress',
                    matched_substrings: [
                        {
                            length: elementZone.name.length,
                            offset: 0,
                        },
                    ],
                    structured_formatting: {
                        main_text: elementZone.name.split(',')[0].trim(),
                        main_text_matched_substrings: [
                            {
                                length: elementZone.name.length,
                                offset: 0,
                            },
                        ],
                    },
                })
            })

            if (resultArray.length === 1) {
                this.setState({
                    origin: {
                        desc: {
                            description: zoneName,
                        },
                        location: { lat: point.lat, lng: point.lon },
                    },
                    destination: {
                        desc: {
                            description: resultArray[0].description,
                        },
                        location: {
                            lat: resultArray[0].location?.lat,
                            lng: resultArray[0].location?.lon,
                        },
                    },
                    destinationsZones: resultArray,
                })
            } else {
                this.setState({
                    origin: {
                        desc: {
                            description: zoneName,
                        },
                        location: { lat: point.lat, lng: point.lon },
                    },
                    destinationsZones: resultArray,
                    destination:
                        this.state.destination.desc !== ''
                            ? {
                                desc: '',
                                location: {},
                            }
                            : this.state.destination,
                })
            }
        })
    }

    onChangeDestination = newValue => {
        let errors = this.state.errors
        errors.destination.message = ''
        if (newValue) {
            if (newValue.type === 'destinationZoneAddress') {
                this.setState({
                    destination: {
                        desc: newValue.description,
                        location: {
                            lat: newValue.location.lat,
                            lng: newValue.location.lon,
                        },
                    },
                })
            } else {
                window.selectAutoCompleteDestination = false
                googleMapsService
                    .getLocation(newValue.description)
                    .then(location => {
                        if (this.state.destination.desc !== '') {
                            this.props.clearMarker(DESTINATION)
                        }
                        this.setState({
                            destination: { desc: newValue, location },
                        })
                        this.props.setMarker(DESTINATION, location)
                    })
            }
        } else {
            this.setState({
                destination: { desc: '', location: null },
            })
            this.props.clearMarker(DESTINATION)
        }
    }

    componentWillUnmount() {
        this.props.clearMarker(ORIGIN)
        this.props.clearMarker(DESTINATION)
        this.props.clearAllMarkers()
    }

    onSubmit() {
        this.validateForm()
        if (this.isFormValid()) {
            const { customerReducer, tripReducer } = this.props
            const trip = factory.createRequestTrip(
                this.state.customer.customerId,
                this.state.origin.location,
                this.state.destination.location,
                this.state.isRequestByDropOff,
                this.state.pickUpStart,
                this.state.quantityPassenger,
                this.state.quantityLuggage,
                this.state.wheelChairs,
                this.state.origin.desc.description
                    ? this.state.origin.desc.description
                    : this.state.origin.desc,
                this.state.destination.desc.description
                    ? this.state.destination.desc.description
                    : this.state.destination.desc,
                this.state.customer,
                customerReducer.langNotification,
                customerReducer.customerPushToken,
                null,
                tripReducer.pickUpStopName,
                tripReducer.dropOffStopName,
                tripReducer.serviceLineDirection,
                tripReducer.originalTripId,
                tripReducer.outboundTripId,
            )

            if (tripReducer.tripToModify == null)
                this.props.sendTrip(
                    trip,
                    this.state.customer.customerId,
                    this.state.noRestriction,
                    tripReducer.requestTripError,
                )
            else {
                const tripModified = factory.createRequestTrip(
                    tripReducer.tripToModify.customerId,
                    tripReducer.tripToModify.pickUpLocation.location,
                    tripReducer.tripToModify.dropOffLocation.location,
                    tripReducer.tripToModify.isRequestByDropOff,
                    this.state.pickUpStart,
                    tripReducer.tripToModify.numPassengers,
                    tripReducer.tripToModify.quantityLuggage,
                    tripReducer.tripToModify.wheelChairs,
                    tripReducer.tripToModify.pickUpLocation.location.address,
                    tripReducer.tripToModify.dropOffLocation.location.address,
                    tripReducer.customerToRequest,
                    customerReducer.langNotification,
                    customerReducer.customerPushToken,
                    tripReducer.tripToModify.passengerId,
                    tripReducer.pickUpStopName,
                    tripReducer.dropOffStopName,
                )
                this.props.modifyTrip(
                    tripModified,
                    tripReducer.tripToModify.customerId,
                    tripReducer.tripToModify.serviceId,
                )
            }
            this.props.clearMarker(ORIGIN)
            this.props.clearMarker(DESTINATION)
        }
    }

    isFormValid() {
        let valid = true
        let properties = Object.getOwnPropertyNames(this.state.errors)
        properties.forEach(element => {
            if (!this.state.errors[element].result) valid = false
        })
        return valid
    }

    validateForm() {
        let errors = this.state.errors
        errors.customer = utils.required(this.state.customer)
        errors.origin = utils.required(this.state.origin.desc)
        errors.destination = utils.required(this.state.destination.desc)
        errors.pickUpStart = utils.required(this.state.pickUpStart)
        this.setState({ errors })
    }

    cancelProcess() {
        this.props.setOriginDistanceTime(null)
        this.props.setDestinationDistanceTime(null)
        this.props.clearAllMarkers()
        if (document.getElementById('div_outbound'))
            document.getElementById('div_outbound').hidden = true
        if (document.getElementById('div_return'))
            document.getElementById('div_return').hidden = true
    }

    onClickBack() {
        history.push('/customers')
    }

    filterTime = (date) => {
        const isPastTime = new Date().getTime() > date.getTime();
        return !isPastTime;
    };

    render() {
        const { t, tripReducer, customerReducer } = this.props
        const canOperate =
            customerReducer.canOperate || tripReducer.tripToModify != null
        const favouriteAddresses = customerReducer.favouriteAddresses.map(x => {
            return {
                description: x.addressPosition.address,
                type: 'favouriteAddress',
                matched_substrings: [
                    { length: x.addressPosition.address.length, offset: 0 },
                ],
                structured_formatting: {
                    main_text: x.addressPosition.address.split(',')[0].trim(),
                    main_text_matched_substrings: [
                        { length: x.addressPosition.address.length, offset: 0 },
                    ],
                },
            }
        })
        const CustomTextField = forwardRef(({ value, onClick }, ref) => (
            <TextField
                variant="outlined"
                label={t('trips.form.page.dateTimeStart')}
                value={value}
                onClick={onClick}
                ref={ref}
                fullWidth
                error={
                    this.state.errors.pickUpStart.message.length === 0
                        ? false
                        : true
                }
                helperText={this.state.errors.pickUpStart.message}
            />
        ))
        const customerOptions = customerReducer.relatedCustomers
        return (
            <React.Fragment>
                <Spinner loading={tripReducer.pending} />
                <Grid container spacing={2} justify='center'>
                    <Grid item md={12} xs={12}>
                        <h2 style={styles.h2}>{this.props.title}</h2>
                    </Grid>
                    <Grid item md={8} xs={10}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="customer"
                                value={this.state.customer}
                                onChange={this.onChangeCustomer}
                                options={customerOptions}
                                disabled={
                                    !this.props.editable ||
                                    tripReducer.tripToModify != null
                                }
                                getOptionSelected={(option, value) =>
                                    option.id == value || option.id == value.id
                                }
                                getOptionLabel={option => option.name || ''}
                                renderOption={option => (
                                    <React.Fragment>
                                        <PersonIcon
                                            color={
                                                !option.color ? 'primary' : ''
                                            }
                                            htmlColor={option.color}
                                            style={{
                                                marginRight: 5,
                                            }}
                                        />
                                        {option.name.trim()}
                                    </React.Fragment>
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant={'outlined'}
                                        label={t('trips.form.page.customer')}
                                        helperText={
                                            this.state.errors.customer.message
                                        }
                                        name="customer"
                                        error={
                                            this.state.errors.customer.message
                                                .length === 0
                                                ? false
                                                : true
                                        }
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={8} xs={10}>
                        <FormControl fullWidth>
                            <GoogleMaps
                                label={t('trips.form.page.origin')}
                                disabled={
                                    !this.props.editable ||
                                    tripReducer.tripToModify != null
                                }
                                onChange={this.onChangeOrigin}
                                name={'origin'}
                                error={this.state.errors.origin}
                                value={this.state.origin.desc}
                                otherValue={this.state.destination.desc}
                                optionsDefault={
                                    customerReducer.address
                                        ? [
                                            {
                                                description:
                                                    customerReducer.address
                                                        .address,
                                                type: 'customerAddress',
                                                matched_substrings: [
                                                    {
                                                        length: customerReducer
                                                            .address.address
                                                            .length,
                                                        offset: 0,
                                                    },
                                                ],
                                                structured_formatting: {
                                                    main_text:
                                                        customerReducer.address.address
                                                            .split(',')[0]
                                                            .trim(),
                                                    main_text_matched_substrings:
                                                        [
                                                            {
                                                                length: customerReducer
                                                                    .address
                                                                    .address
                                                                    .length,
                                                                offset: 0,
                                                            },
                                                        ],
                                                },
                                            },
                                        ].concat(favouriteAddresses)
                                        : favouriteAddresses
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={8} xs={10}>
                        <FormControl fullWidth>
                            <GoogleMaps
                                label={t('trips.form.page.destination')}
                                disabled={
                                    !this.props.editable ||
                                    tripReducer.tripToModify != null
                                }
                                onChange={this.onChangeDestination}
                                name={'destination'}
                                value={this.state.destination.desc}
                                otherValue={this.state.origin.desc}
                                error={this.state.errors.destination}
                                optionsDefault={
                                    customerReducer.address
                                        ? [
                                            {
                                                description:
                                                    customerReducer.address
                                                        .address,
                                                type: 'customerAddress',
                                                matched_substrings: [
                                                    {
                                                        length: customerReducer.address.address.split(
                                                            ',',
                                                        )[0].length,
                                                        offset: 0,
                                                    },
                                                ],
                                                structured_formatting: {
                                                    main_text:
                                                        customerReducer.address.address
                                                            .split(',')[0]
                                                            .trim(),
                                                    main_text_matched_substrings:
                                                        [
                                                            {
                                                                length: customerReducer.address.address.split(
                                                                    ',',
                                                                )[0].length,
                                                                offset: 0,
                                                            },
                                                        ],
                                                },
                                            },
                                        ]
                                            .concat(favouriteAddresses)
                                            .concat(
                                                this.state.destinationsZones,
                                            )
                                        : favouriteAddresses.concat(
                                            this.state.destinationsZones,
                                        )
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={8} xs={10}>
                        <SelectRequestType
                            editable={this.props.editable}
                            isRequestByDropOff={this.state.isRequestByDropOff}
                            onChange={(value) => this.setState({ isRequestByDropOff: value })}
                        />
                    </Grid>

                    <Grid item md={8} xs={10}>
                        <FormControl fullWidth>
                            <DatePicker
                                popperProps={{ positionFixed: true }}
                                selected={this.state.pickUpStart}
                                onChange={date => {
                                    const errors = this.state.errors
                                    this.setState({ pickUpStart: date })
                                    if (tripReducer.requestTripError) {
                                        this.props.updateDateOfTripFromDatePicker(
                                            date,
                                        )
                                    }
                                    errors['pickUpStart'].message = ''
                                }}
                                showTimeSelect
                                disabled={
                                    !this.props.editable &&
                                    (!tripReducer.requestTripError ||
                                        tripReducer.serviceSelected
                                            ?.serviceType ==
                                        SERVICES_TYPE.regularWithSchedule)
                                }
                                locale={i18next.language}
                                timeIntervals={5}
                                maxDate={setYear(new Date(), 9999)}
                                minDate={new Date()}
                                filterTime={this.filterTime}
                                name="pickUpStart"
                                timeCaption={this.state.timeText}
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm"
                                customInput={<CustomTextField />}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={8} xs={10}>
                        <FormControl fullWidth>
                            <TextField
                                InputLabelProps={{
                                    style: { pointerEvents: 'auto' },
                                    shrink: true,
                                }}
                                value={this.state.quantityPassenger}
                                label={t('trips.form.page.quantityPassenger')}
                                name="quantityPassenger"
                                inputProps={{ min: 1 }}
                                disabled={
                                    !this.props.editable ||
                                    tripReducer.tripToModify != null
                                }
                                fullWidth
                                type={'number'}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={8} xs={10}>
                        <FormControl fullWidth>
                            <TextField
                                InputLabelProps={{
                                    style: { pointerEvents: 'auto' },
                                    shrink: true,
                                }}
                                value={this.state.quantityLuggage}
                                label={t('trips.form.page.quantityLuggage')}
                                name="quantityLuggage"
                                inputProps={{ min: 0 }}
                                disabled={
                                    !this.props.editable ||
                                    tripReducer.tripToModify != null
                                }
                                fullWidth
                                type={'number'}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={8} xs={10}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.wheelChairs}
                                    disabled={
                                        !this.props.editable ||
                                        tripReducer.tripToModify != null ||
                                        (this.state.wheelChairs && this.state.customerUsesWheelChair)
                                    }
                                    required
                                    onChange={() =>
                                        this.setState({
                                            wheelChairs:
                                                !this.state.wheelChairs,
                                        })
                                    }
                                    color="primary"
                                />
                            }
                            label={t('trips.form.page.quantityWheelChairs')}
                        />
                    </Grid>
                    <Grid item md={8} xs={10}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.noRestriction}
                                    disabled={
                                        !this.props.editable ||
                                        tripReducer.tripToModify != null
                                    }
                                    required
                                    onChange={() =>
                                        this.setState({
                                            noRestriction:
                                                !this.state.noRestriction,
                                        })
                                    }
                                    color="primary"
                                />
                            }
                            label={t('trips.form.page.noRestriction')}
                        />
                    </Grid>
                    <Grid container item justify='center' spacing={2} style={{ marginTop: 20 }}>
                        <Grid item md={8} xs={10}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                size="large"
                                disabled={
                                    !!(
                                        tripReducer.pending ||
                                        !canOperate ||
                                        !this.props.editable
                                    )
                                }
                                onClick={this.onSubmit}
                            >
                                {t('trips.form.page.buttonOK')}
                            </Button>
                        </Grid>
                        {!this.props.editable ? (
                            <Grid item md={8} xl={5} xs={10}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    onClick={this.cancelProcess}
                                >
                                    {t('trips.form.page.buttonCancel')}
                                </Button>
                            </Grid>
                        ) : (
                            <Grid item md={8} xs={10}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    onClick={this.onClickBack}
                                >
                                    {t('trips.form.page.goBack')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>

                </Grid>

            </React.Fragment>
        )
    }
}

export default RequestTripForm
